import LinkComponent from '@pretto/bricks/website/utility/Link'

import PropTypes from 'prop-types'

import * as S from '../styles'

const Link = ({ children, href }) => (
  <S.TextLink>
    <LinkComponent href={href}>{children}</LinkComponent>
  </S.TextLink>
)

Link.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
}

export default Link
